<template>
  <div class="d-flex  align-center" :class="[{ 'justify-end' : user }, { 'justify-space-between' : !user }]" style="width: 100%">
    <!--   Show/hide display button  -->
    <v-sheet
      v-if="!user"
      class="px-2 "
      :style="`width: 56px; height: 56px; background-color: ${wsDARKER}; border-top-right-radius: 8px;
                border-bottom-right-radius: 8px; text-align: center; padding: 7px 0;`">
        <ws-tooltip  top :text="$t('webinar.main.preview.set_displays')">
          <div class="text-center pointer" @click="showScreensVisibility">
            <v-icon v-if="!$store.state.webinars.displayVideoInputSidebar" color="#fff" style="margin-bottom: 6px;">mdi-monitor</v-icon>
            <v-img v-else :src="require('@/modules/webinars/assets/hide-displays.svg')" color="#fff" style="width: 24px; height: 24px; margin: 0 auto 6px auto" ></v-img>
            <h6 :style="`color: ${wsACCENT}; `">{{ $t('webinar.main.preview.displays') }}</h6>
          </div>
        </ws-tooltip>
    </v-sheet>

    <!--  Media selector  -->
    <div>
      <webinar-media-selector v-if="!user" display-share-window/>
    </div>


    <!--  Settings - View settings && Mobile/desktop view -->
    <v-hover v-if="!user" v-slot="{ hover }">
      <div class="d-flex justify-space-between align-center" style="column-gap: 16px">
        <v-skeleton-loader
          :loading="loading"
          type="card-avatar"
          width="37px"
          :class="{ 'skeleton-bg small' : loading }"
          class="rounded-lg"
          height="32px"
        >
          <ws-tooltip top :text="$t('webinar.main.preview.set_view')" >
            <ft-select
                v-model="$store.state.stream.canvasCascadeType"
                :items="CANVAS_CASCADE_SELECT"
            >
              <div
                  class="text-center pointer px-2 rounded-lg"
                  v-ripple
                  style="width: 56px; padding: 5px 0;"
                  :style="{ 'background-color': hover ? '#3C5467' : 'transparent' }"
              >
                <v-icon color="#fff" class="mb-1">mdi-credit-card-chip</v-icon>
                <h6 class="font-weight-regular wsDARKLIGHT" >{{ $t('webinar.main.preview.view') }}</h6>
              </div>
            </ft-select>

          </ws-tooltip>
        </v-skeleton-loader>

        <v-skeleton-loader
          :loading="loading"
          type="card-avatar"
          width="fit-content"
          :class="{ 'skeleton-bg small' : loading }"
          class="rounded-lg"
          height="32px"
        >
          <div style="border: 2px solid #9AB2C4" class="rounded">
            <ws-button-group :items="contentView" value="desktop" dense/>
          </div>
        </v-skeleton-loader>
      </div>
    </v-hover>

    <!-- Icon "raise the hand" and prompt dialog -->
    <div v-if="user" style="margin-left: auto;">
      <v-icon style="cursor: pointer;" :color="wsATTENTION">
        mdi-hand-back-right
      </v-icon>

      <ws-prompt-dialog
        v-if="displayInstructionsDialog"
        v-model="displayInstructionsDialog"
        :backgroundColor="wsWHITE"
        :description="$t('webinar.student.raise_hand_text')"
        bottom
        x="120px"
        y="120px"
        :text-color="wsACCENT"
        line-position="16px"
        width="310px"
      >
      </ws-prompt-dialog>
    </div>

  </div>
</template>

<script>
import webinarMediaSelector from "@modules/webinars/components/Webinar/admin/room/UI/controls/webinarMediaSelector.vue";
import webinars from "@modules/webinars/mixins/webinars";
import wsPromptDialog from "@modules/webinars/components/UI/wsPromptDialog.vue";
export default {
  name: "webinarVideoControls",
  components: {
    wsPromptDialog,
    webinarMediaSelector
  },
  mixins: [webinars],
  props: {
    user: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displayInstructionsDialog: true,
    }
  },
  computed : {
    loading() {
      return !this.WEBINAR.uuid
    },
    contentView() {
      return [
        {icon: 'mdi-laptop', value: 'desktop', tooltip: this.$t('webinar.main.preview.desktop'), tooltip_top: true},
        {icon: 'mdi-cellphone', value: 'mobile', tooltip: this.$t('webinar.main.preview.mobile'), tooltip_top: true},
      ]
    },
  },
  methods: {
    showScreensVisibility() {
      this.$store.state.webinars.displayVideoInputSidebar = !this.$store.state.webinars.displayVideoInputSidebar
    }
  }
}
</script>


<style scoped>

</style>
