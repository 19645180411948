export default {
    computed : {

        //Entities Params

        WEBINAR() {
            return this.$store.state.webinars.webinar || {}
        },

        ONLINE_PARTICIPANTS_COUNT() {
            return this.$store.state.webinars.onlineParticipantsCount || 0
        },

        DISPLAY_VIDEO_INPUT_SIDEBAR() {
            return this.$store.state.webinars.displayVideoInputSidebar || false
        },


        // Streaming params

        CANVAS_CASCADE() {
            return this.$store.state.stream.canvasCascadeType
        },
        CANVAS_CASCADE_SELECT() {
            return [
                { text : 'Default'         , value : 'default'         },
                { text : 'verticalStretched' , value : 'verticalStretched' },
                { text : 'Presentation'    , value : 'presentation'    },
                { text : 'MainRight'       , value : 'mainRight'       },
                { text : 'MainTop'         , value : 'mainTop'         },
                { text : 'ZOLOTAR'         , value : 'ZOLOTAR'         }
            ]
        },
        IS_ON_AIR() {
            return this.$store.state.stream.isOnAir
        },

        START_STREAM_TRIGGER() {
            return this.$store.state.stream.startStreamTrigger
        },

        MAIN_INPUT_STREAM() {
            return this.$store.state.stream.mainInputStream
        },

        STREAMS_SELECT() {
           return [this.MAIN_STREAM , ...this.additionalStreams]
        },

        VIDEO_SOURCES() {
            return this.$store.state.stream.videoSources
        },
        ACTIVE_VIDEO_SOURCES() {
            return this.VIDEO_SOURCES.filter(el => el.active)
        },

        AUDIO_SOURCES() {
            return this.$store.state.stream.audioSources
        },

        MEDIA_CONTROLLER() {
            return this.$store.state.stream.wsMediaController
        },

        SELECTED_CAMERA() {
            return this.$store.state.stream.videoDeviceId
        },
        SELECTED_MICROPHONE() {
            return this.$store.state.stream.audioDeviceId
        },

        VIDEO_IS_ON() {
            return this.$store.state.stream.videoOn
        },

        MICROPHONE_IS_ON() {
            return this.$store.state.stream.microphoneOn
        },

        DEVICES_ACCESS_PERMITTED() {
            return this.$store.state.stream.isMediaReady
        },

        CAMERAS_SELECT() {
            if (!this.MEDIA_CONTROLLER) {
                return []
            }

            return this.MEDIA_CONTROLLER.getCamerasSelect()
        },

        MICROPHONES_SELECT() {
            if (!this.MEDIA_CONTROLLER) {
                return []
            }

            return this.MEDIA_CONTROLLER.getMicrophonesSelect()
        },

    },
}