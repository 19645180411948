import cascadeSchemes from "@modules/webinars/plugins/streamCanvasBuilder/cascadeSchemes";
class StreamCanvasBuilder {
    constructor() {
    }

    /**
     * @param {CanvasRenderingContext2D} context - The canvas rendering context.
     * @param {Array} sources - The sources to draw on the canvas.
     * @param {HTMLCanvasElement} canvas
     * @param canvasCascadeType
     */
    drawCanvas(context , sources,canvas , canvasCascadeType) {

        context.clearRect(0, 0, canvas.width, canvas.height);

        if (sources.length === 1) {
            cascadeSchemes.default.drawCanvas(context,sources,canvas)
        }
        else if (cascadeSchemes[canvasCascadeType] && cascadeSchemes[canvasCascadeType].drawCanvas) {
            cascadeSchemes[canvasCascadeType].drawCanvas(context,sources,canvas)
        } else {
            context.fillStyle = 'red';
            context.font = "60px Arial";
            context.textAlign = "center";
            context.fillText(`No draw function found`, canvas.width / 2, canvas.height/2);
        }


        context.fillStyle = 'black';
        context.font = "30px Arial";
        context.textAlign = "center";
        context.fillText(`Canvas type is : ${canvasCascadeType}`, canvas.width / 2, canvas.height - 10);

            // context.fillStyle = 'rgb(65,84,103)'
            // context.fillRect(20, 50, 300, 50)

    }


}

export default StreamCanvasBuilder