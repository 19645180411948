/**
 * @param {CanvasRenderingContext2D} context - The canvas rendering context.
 * @param {Array} sources - The sources to draw on the canvas.
 * @param {HTMLCanvasElement} canvas
 * @param canvasCascadeType
 */


function drawOne(context,source,canvas) {
    context.drawImage(source.dom, 0, 0, canvas.width, canvas.height)
}

function drawTwo(context,sources,canvas) {

    for (let index in sources) {
        const source = sources[index];

        const padding = 12;
        const width = canvas.width/2 - (padding*1.5)
        const height = canvas.height/2 - (padding*1.5)

        let x = padding
        if (index > 0 ) {
            x = padding + width + padding
        }
        let y =  (canvas.height/4)

        context.drawImage(source.dom, x, y, width, height)
    }

}

function drawMultiple(context,sources,canvas) {
    const sourcesCount = sources.length

    let gridSize = Math.ceil(Math.sqrt(sourcesCount));

    for (let index = 0; index < sourcesCount; index++) {
        const source = sources[index];

        const row = Math.floor(index / gridSize);
        const column = index % gridSize;

        const padding = 12;
        const width = canvas.width / gridSize - padding * 1.5;
        const height = canvas.height / gridSize - padding * 1.5;

        let x = padding + column * (width + padding);
        let y = padding + row * (height + padding);

        context.drawImage(source.dom, x, y, width, height);
    }
}

function drawCanvas(context,sources,canvas) {

    const sourcesCount = sources.length
    if (!sourcesCount) {
        return
    }

    switch (sourcesCount) {
        case 1 : drawOne(context,sources[0],canvas); return
        case 2 : drawTwo(context,sources,canvas); return
        default : drawMultiple(context,sources,canvas); return
    }

}

export default {
    drawCanvas : drawCanvas
}