/**
 * @param {CanvasRenderingContext2D} context - The canvas rendering context.
 * @param {Array} sources - The sources to draw on the canvas.
 * @param {HTMLCanvasElement} canvas
 */
function drawTwo(context,sources,canvas) {

    for (let index in sources) {
        const source = sources[index];

        const width = canvas.width/2
        const height = canvas.height

        let x = 0
        let y =  0
        if (index > 0 ) {
            x = canvas.width/2
        }


        const sourceX = canvas.width/4

        context.drawImage(source.dom, sourceX, 0, width ,canvas.height , x,y, width, height)
    }

}

function drawCanvas(context,sources,canvas) {

    const sourcesCount = sources.length

    switch (sourcesCount) {
        case 2 : drawTwo(context,sources,canvas); return
    }

}

export default {
    drawCanvas : drawCanvas
}